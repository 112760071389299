const TribesMetaDataArabic = {
  region: [
    {
      value: "الشرق الأوسط",
      label: "الشرق الأوسط",
    },
    {
      value: "استراليا",
      label: "استراليا",
    },
    {
      value: "افريقيا الوسطى",
      label: "افريقيا الوسطى",
    },
    {
      value: "اوروبا",
      label: "اوروبا",
    },
    {
      value: "أمريكا الشمالية",
      label: "أمريكا الشمالية",
    },
    {
      value: "شمال أفريقيا",
      label: "شمال أفريقيا",
    },
    {
      value: "شمال اسيا",
      label: "شمال اسيا",
    },
    {
      value: "أمريكا الجنوبية",
      label: "أمريكا الجنوبية",
    },
    {
      value: "جنوب أفريقيا",
      label: "جنوب أفريقيا",
    },
    {
      value: "جنوب اسيا",
      label: "جنوب اسيا",
    },
    {
      value: "قارة",
      label: "قارة",
    },
  ],
  climate: [
    {
      value: "جاف",
      label: "جاف",
    },
    {
      value: "قطبي",
      label: "قطبي",
    },
    {
      value: "استوائي",
      label: "استوائي",
    },
    {
      value: "معتدل",
      label: "معتدل",
    },
  ],
  challenge: [
    {
      value: "متوسط",
      label: "متوسط",
    },
    {
      value: "مبتدئ",
      label: "مبتدئ",
    },
    {
      value: "صعب",
      label: "صعب",
    },
  ],
  transport: [
    {
      value: "حافلة",
      label: "حافلة",
    },
    {
      value: "حافلة",
      label: "سيارة",
    },
    {
      value: "عبّارة",
      label: "عبّارة",
    },
    {
      value: "طيران",
      label: "طيران",
    },
    {
      value: "مشياً",
      label: "مشياً",
    },
  ],
};

/**
 * Find label corresponding to the type and value
 *
 * @param {String} type
 * @param {String} value
 */
function findLabelArabic(type, value) {
  for (let i = 0; i < TribesMetaDataArabic[type].length; i++) {
    if (TribesMetaDataArabic[type][i]["value"] === value) {
      return TribesMetaDataArabic[type][i]["label"];
    }
  }
  return "Not Provided!";
}

export { TribesMetaDataArabic, findLabelArabic };
